<template>
  <div>
    <enrollment-header />
    <!-- <enrollment-navigation-drawer :items="items" /> -->
    <v-main class="pt-3">
      <router-view />
    </v-main>
    <enrollment-footer />
  </div>
</template>

<script>
import Header from '@/components/core/Header.vue';
// import NavigationDrawer from '@/components/core/NavigationDrawer.vue';
import Footer from '@/components/core/Footer.vue';

export default {
  name: 'DashboardLayout',
  components: {
    EnrollmentHeader: Header,
    // EnrollmentNavigationDrawer: NavigationDrawer,
    EnrollmentFooter: Footer,
  },
  // computed: {
  //   items() {
  //     return [
  //       { title: this.$t('navigation.colleges'), path: '/dashboard/colleges' },
  //       { title: this.$t('navigation.students'), path: '/dashboard/students' },
  //       { title: this.$t('navigation.teachers'), path: '/dashboard/teachers' },
  //       { title: this.$t('navigation.parents'), path: '/dashboard/parents', disabled: true },
  //       { title: this.$t('navigation.reports'), path: '/dashboard/reports', disabled: true },
  //     ];
  //   },
  // },
};
</script>
